import { commitMutation, renderState, renderGetter, callMutation } from '@/helpers/vuex';
import { getFinishedOrder } from '@/api/get-order-finished';
import { componentMapping, getUserBindChtAccount, hasFlagProp } from '@/components/common/cart-finished-constant';
import { isMobile, getOrderListPath } from '@/helpers/is-from-app';
import { createParameterArray, sendGA4sEvent, purchase } from '@/helpers/tracking/ga';
import { sendPageConfirmation } from '@/helpers/tracking/insider';

const methods = {
  getComponentName(paymentType) {
    // 取出 paymentType 對應的 component
    const type = componentMapping.filter((x) => x.type === paymentType);
    // 無對應的付款類型視為失敗
    if (type.length === 0) {
      return 'auth-fail';
    }

    return type[0]['name'];
  },
  setElementData() {
    /** 呼叫 API 取得完成訂單資料 */
    getFinishedOrder()
      .then((res) => {
        if (res.data.code === 0) {
          window.location.href = getOrderListPath();
          return;
        }
        // 存訂單資料
        this.order = {
          id: res.data.data.orderId || '',
          bank: res.data.data.bank || '',
          amount: res.data.data.realAmount,
          period: res.data.data.installments || '',
          perAmount: res.data.data.eachAmount || '',
          account: res.data.data.paymentId || '',
          expireDate: res.data.data.expireDate || '',
          usedPoint: res.data.data.redeemUsedPoint || '',
          unusedPoint: res.data.data.redeemRemainPoint || '',
          discount: res.data.data.redeemUsedMoney || '',
          qrCodeUrl: res.data.data.qrCodeImage || '',
          isFromApp: res.data.data.isFromApp || '',
        };
        commitMutation('OrderFinishInfo', 'Order', res.data.data);
        // 是否有 links flag 及是否有內容
        if (hasFlagProp(res, 'links')) {
          const hasLinkLength = res.data.data.links.length;
          if (hasLinkLength) {
            commitMutation('OrderFinishInfo', 'isShowInsurance', true);
          }
        }
        if (hasFlagProp(res, 'bindChtAccount')) {
          const { bindChtAccount } = res.data.data;
          getUserBindChtAccount(bindChtAccount);
        }
        // 依 付款方式取得 component
        this.paymentType = this.getComponentName(res.data.data.paymentKind);
        commitMutation('Cart', 'isFromApp', res.data.data.isFromApp);

        // 追蹤碼紀錄
        if (
          typeof this.order.id !== 'undefined' &&
          this.order.id.length >= 10 &&
          this.paymentType.indexOf('fail') === -1
        ) {
          const cartDetail = res.data.data.details || renderState('CartDetail', 'cartDetail') || [];
          const shipping = res.data.data.shipFare || renderGetter('AmountDetail', 'realShipFare') || 0;
          const oriCartDetail = renderState('CartDetail', 'cartDetail') || [];
          // ga, facebook, ecsearch
          purchase(cartDetail, this.order.id, this.order.amount, shipping);
          // GA4
          const itemParameters = createParameterArray(oriCartDetail);
          const defaultParameterForGA = {
            currency: 'TWD',
            transaction_id: res.data.data.orderId,
            value: res.data.data.realAmount,
            items: itemParameters,
          };
          sendGA4sEvent('purchase', defaultParameterForGA);
          // Insider
          if (!res.data.data.sentPurchaseOrder) {
            sendPageConfirmation(res.data.data.orderId, res.data.data.details);
          }
        }
        callMutation('AmountDetail', 'RESET_STATE');
        return res.data.data.is2rFriend === 1;
      })
      .then((flag) => this.showModal(flag))
      .catch((error) => {
        console.log(error);
        window.location.href = isMobile()
          ? `${process.env.VUE_APP_CART}/m/cart/choice`
          : `${process.env.VUE_APP_CART}/cart/finished`;
      }); // 500 或者 4xx

    // 清除 store 內參數
    commitMutation('AmountDetail', 'checksum', '');
    commitMutation('AmountDetail', 'csrf', {
      hash: '',
      token: '',
    });
  },
  showModal(flag) {
    // 非 much much 訂單就不詢問是否加好友了
    if (!flag) {
      return false;
    }
  },
};

const created = function () {
  // 設定畫面上的元素
  this.setElementData();

  // 發票 store 增加 reset 功能並於 finished 頁清理
  callMutation('InvoiceInfo', 'RESET_STATE');
  callMutation('DynamicComponents', 'RESET_STATE');
};

export default {
  methods,
  created,
};
