<template>
  <a v-if="isNotFromMuchMuch" :href="orderUrl()" :class="urlClass()"> 訂單查詢 </a>
</template>

<script>
import { isNotFromMuchMuch } from '@/helpers/is-from-app';
import { pcOrderListUri, mobileOrderListUri } from '@/components/common/cart-finished-constant';

const props = {
  isMobile: Boolean,
};

const methods = {
  orderUrl() {
    return this.isMobile ? mobileOrderListUri : pcOrderListUri;
  },
  /** 手機板、PC版的連結樣式不同 */
  urlClass() {
    return {
      inverted: this.isMobile,
      underline: true,
    };
  },
};

export default {
  name: 'query-order-wording',
  props,
  methods,
  computed: {
    isNotFromMuchMuch,
  },
};
</script>
