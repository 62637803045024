<template>
  <div>
    <div id="m-bindModal" class="modal fade cht_bind" data-toggle="modal" data-backdrop="static" data-keyboard="false">
      <div class="floatCheck-container">
        <div class="floatCheck-Closebtn">
          <a class="btn-link" href="javascript:void(0);" data-dismiss="modal">
            <i class="icon icon-close"></i>
          </a>
        </div>
        <div class="floatCheck-container-main">
          <div class="floatCheck-container-box">
            <div class="text-center">
              <b class="fn-md price text-center">結帳使用 HamiPoint，１點可折抵１元</b>
              <p class="fn-md text-muted text-center">綁定中華會員帳號</p>
              <h3 class="edenred-point divider fn-lg blue text-center">
                <b class="blue">{{ chtAccount }}</b>
              </h3>
              <p class="fn-md text-muted text-center">未來結帳即可直接查看 / 選用 HamiPoint</p>
              <div class="text-center spacing top">
                <a
                  :class="['btn btn-primary-outline spacing right', { 'btn-round': isMobile }]"
                  href="javascript:void(0);"
                  data-dismiss="modal">
                  不綁定
                </a>
                <a
                  :class="['btn btn-primary', { 'btn-round': isMobile }]"
                  href="javascript:void(0);"
                  @click="redirectToBind()">
                  綁定
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="bindModal" class="modal fade cht_bind" data-toggle="modal" data-backdrop="static" data-keyboard="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body plump container">
            <div class="col-md-16">
              <h4 class="notice spacing bottom mini">結帳使用 Hami Point，１點可折抵１元</h4>
              <p class="fn-root spacing bottom mini">
                綁定中華會員帳號
                <b class="info fn-lg middle">{{ chtAccount }}</b>
                ，未來結帳即可直接查看 / 選用 Hami Point
              </p>
            </div>
            <div class="col-md-8">
              <div class="btn-group text-right spacing top">
                <a
                  :class="['btn btn-query-outline btn-md', { 'btn-round': isMobile }]"
                  href="javascript:void(0);"
                  data-dismiss="modal"
                  aria-label="Close">
                  不綁定
                </a>
                <a
                  :class="['btn btn-query btn-md', { 'btn-round': isMobile }]"
                  href="javascript:void(0);"
                  @click="redirectToBind()">
                  綁定
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mobileDevice from '@/helpers/device';
import { renderState } from '@/helpers/vuex';

const mounted = () => {
  const modalElem = this.isMobile ? 'm-bindModal' : 'bindModal';
  const thisModalElem = document.getElementById(modalElem);
  if (thisModalElem === null) {
    // eslint-disable-next-line no-console
    console.error(`[modal] 無法取得 DOM 元件`);
    return;
  }
  const myModal = new Modal(thisModalElem, { backdrop: 'static' });
  setTimeout(() => myModal.show(), 250);
};

const redirectToBind = () => {
  window.location.href = `${process.env.VUE_APP_ONLINE}/hami/bind`;
};

export default {
  name: 'modal-cht-bind',
  computed: {
    chtAccount() {
      return renderState('OrderFinishInfo', 'bindChtAccount');
    },
    isMobile() {
      return (mobileDevice.mobile() || mobileDevice.tablet()) !== null;
    },
  },
  methods: {
    redirectToBind,
  },
  mounted,
};
</script>
