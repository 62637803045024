import { getChtBindStatus } from '@/api/get-cht-bind-status';
import { commitMutation } from '@/helpers/vuex';

export const localStorageKey = 'CART_FINISH_ORDER';

const myDefaultJSON = {
  flag: { paymentType: 'others', isShowAddFriend: false },
  order: {
    id: '',
    bank: '',
    amount: '',
    period: '',
    perAmount: '',
    account: '',
    expireDate: '',
    usedPoint: '',
    discount: '',
    unusedPoint: '',
    qrCodeUrl: '',
    orderUrl: '',
  },
};

export const defaultJson = JSON.stringify(myDefaultJSON);

export const defaultOrder = {
  id: '',
  bank: '',
  amount: '',
  period: '',
  perAmount: '',
  account: '', // ATM
  expireDate: '', // ATM + CCR
  usedPoint: '', // 紅利折抵
  discount: '', // 紅利折抵 -> 紅利折抵金額
  unusedPoint: '', // 紅利折抵 -> 剩餘紅利
  qrCodeUrl: '', // 手機板 ccr qrCode網址
};

export const defaultPaymentType = 'others';

export const mobileOrderListUri = `${process.env.VUE_APP_ONLINE}/m/order/list`;

export const pcOrderListUri = `${process.env.VUE_APP_ONLINE}/order/list`;

export const mobileCartMain = '/m/cart/main';

export const pcCartMain = '/cart/main';

export const cocoBack = process.env.VUE_APP_COCO_BACK;

export const online = process.env.VUE_APP_ONLINE;

export const componentMapping = [
  {
    type: 'ZERO',
    name: 'zero-order',
  },
  {
    type: 'CREDIT_CARD_ONCE',
    name: 'pay-off',
  },
  {
    type: 'CREDIT_CARD_INSTALLMENT',
    name: 'installment',
  },
  {
    type: 'ATM',
    name: 'atm',
  },
  {
    type: 'CREDIT_CARD_BONUS',
    name: 'bonus',
  },
  {
    type: 'SENAO_MART',
    name: 'ccr',
  },
  {
    type: 'LINE_PAY',
    name: 'line-pay',
  },
  {
    type: 'UNKNOWN',
    name: 'others',
  },
  {
    type: 'FAIL',
    name: 'auth-fail',
  },
  {
    type: 'JKO_PAY',
    name: 'jko-pay',
  },
  {
    type: 'JKOS_PAY',
    name: 'jko-pay',
  },
  {
    type: 'PI_PAY',
    name: 'pi-pay',
  },
  {
    type: 'TW_PAY',
    name: 'tw-pay',
  },
  {
    type: 'PLUS_PAY',
    name: 'plus-pay',
  },
  {
    type: 'APPLE_PAY',
    name: 'apple-pay',
  },
  {
    type: 'EASY_PAY',
    name: 'easy-pay',
  },
  {
    type: 'ZINGALA',
    name: 'zingala-pay',
  },
];

const getChtBindStatusResponse = (res) => {
  const fetchSuccess = 1;
  if (res.data.code !== fetchSuccess) {
    return;
  }
  const { bindChtAccount } = res.data.data;
  commitMutation('OrderFinishInfo', 'bindChtAccount', bindChtAccount);
};

const getChtBindStatusCatch = (error, bindChtAccount) => {
  commitMutation('OrderFinishInfo', 'bindChtAccount', bindChtAccount);
  console.log(error);
};

export const hasFlagProp = (res, prop) => Object.prototype.hasOwnProperty.call(res.data.data, prop);

export const getUserBindChtAccount = (bindChtAccount) => {
  getChtBindStatus()
    .then((res) => getChtBindStatusResponse(res))
    .catch((e) => getChtBindStatusCatch(e, bindChtAccount));
};
