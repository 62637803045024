<template>
  <div class="btn-group text-left">
    <div class="btn-group"></div>
    <a :href="cartMainUrl()" :class="['btn btn-secondary', { 'btn-round': isMobile }]"> 回購物車 </a>
    <a v-if="isNotFromMuchMuch" :href="orderListUrl()" :class="['btn btn-primary', { 'btn-round': isMobile }]">
      訂單查詢
    </a>
  </div>
</template>

<script>
import { isNotFromMuchMuch } from '@/helpers/is-from-app';
import {
  pcOrderListUri,
  mobileOrderListUri,
  mobileCartMain,
  pcCartMain,
} from '@/components/common/cart-finished-constant';

const props = {
  isMobile: Boolean,
};

const methods = {
  orderListUrl() {
    return this.isMobile ? mobileOrderListUri : pcOrderListUri;
  },
  cartMainUrl() {
    return this.isMobile ? mobileCartMain : pcCartMain;
  },
};

export default {
  name: 'back-to-cart-button-group',
  props,
  methods,
  computed: {
    isNotFromMuchMuch,
  },
};
</script>
