<template>
  <div>
    <div v-if="isMobile">
      <i class="icon icon-xxl icon-check-circle success"></i>
      <h3 class="spacing top cartstep3success">訂單確認送出</h3>
      <p class="spacing bottom" v-if="paymentMethod === 'bonus' || paymentMethod === 'installment'">
        感謝您的訂購與支持！
        <br />
        商品將於訂單付款成功後2-5工作天送達您指定的地址，如屬預購或特殊性商品將依網頁說明出貨時間為主。
      </p>
      <p class="spacing bottom" v-else>感謝您此次的訂購，系統已發出訂單確認通知信到您的Email信箱，請您核對。</p>
      <div class="alert aler-ordersuccess">
        <p class="spacing bottom mini">
          您的訂單編號為
          <br />
          <b class="value fn-lg">{{ order.id }}</b>
        </p>
        <p class="text-muted">
          <span class="name" v-if="order.bank">
            {{ order.bank }}
          </span>
          <span class="name" v-if="payment[paymentMethod]">
            {{ payment[paymentMethod] }}
          </span>
          <span class="text-muted" v-if="order.amount"> 本次消費金額 </span>
          <span v-if="order.amount">
            <b class="value spacing fn-md">${{ order.amount }}</b>
          </span>
        </p>
        <p class="text-muted" v-if="paymentMethod === 'zingala-pay'">(分期實付金額以與服務商申請議定內容為準)</p>
        <p class="text-muted" v-if="order.perAmount">(每期{{ order.perAmount | dollar }}×{{ order.period }}期)</p>
        <p class="text-muted" v-if="order.usedPoint">
          本次折抵點數 {{ order.usedPoint | NumberWithPoint }} 點 (折抵{{ order.discount | dollar }}元)
        </p>
        <p v-if="order.unusedPoint" class="text-muted fn-sm">剩餘點數 {{ order.unusedPoint | NumberWithPoint }} 點</p>
      </div>
      <p class="spacing bottom sm">
        提醒您，如欲查詢出貨進度，請至
        <query-order-wording is-mobile></query-order-wording>
      </p>
      <h5 class="spacing bottom md">再次感謝您的惠顧，謝謝！</h5>
    </div>

    <div v-else>
      <h2 class="spacing top lg blue">訂單確認送出</h2>
      <p class="stackable" v-if="paymentMethod === 'bonus' || paymentMethod === 'installment'">
        感謝您的訂購與支持！
        <br />
        商品將於訂單付款成功後2-5工作天送達您指定的地址，如屬預購或特殊性商品將依網頁說明出貨時間為主。
      </p>
      <p class="stackable" v-else>感謝您此次的訂購，系統已發出訂單確認通知信到您的Email信箱，請您核對。</p>
      <div class="bar gray w-fix-md has-inline divider horizontal">
        <p class="sticky">
          <span class="spacing right sm middle">您的訂單編號為</span>
          <b class="price fn-lg middle">{{ order.id }}</b>
        </p>
        <p class="sticky">
          <span class="name" v-if="order.bank">
            {{ order.bank }}
          </span>
          <span class="name" v-if="payment[paymentMethod]">
            {{ payment[paymentMethod] }}
          </span>
          <span class="name" v-if="order.amount"> 本次消費金額 </span>
          <span class="price spacing fn-md" v-if="order.amount">
            {{ order.amount | dollar }}
          </span>
          <span v-if="order.perAmount">(每期 {{ order.perAmount | dollar }} X {{ order.period }}期)</span>
        </p>
        <p v-if="paymentMethod === 'zingala-pay'">(分期實付金額以與服務商申請議定內容為準)</p>
        <p v-if="order.usedPoint">
          本次折抵點數 {{ order.usedPoint | NumberWithPoint }} 點 (折抵{{ order.discount | dollar }}元)
        </p>
        <p v-if="order.unusedPoint">剩餘點數 {{ order.unusedPoint | NumberWithPoint }} 點</p>
      </div>
      <p>
        提醒您，如欲查詢出貨進度，請至
        <query-order-wording></query-order-wording>
      </p>
      <h4 class="spacing bottom lg">再次感謝您的惠顧，謝謝！</h4>
    </div>

    <continue-bar :is-mobile="isMobile"></continue-bar>
  </div>
</template>

<script>
import { dollar, NumberWithPoint } from '@/helpers/format/dollar';
import continueBar from '@/components/common/continue-buy-button-group.vue';
import queryOrderWording from '@/components/common/query-order-wording.vue';

const data = function () {
  return {
    payment: {
      'apple-pay': 'Apple Pay',
      'line-pay': 'Line Pay',
      'jko-pay': '街口支付',
      'tw-pay': '台灣 Pay',
      'pi-pay': 'Pi拍錢包',
      'plus-pay': '全盈+PAY',
      'easy-pay': '悠遊付',
      'zingala-pay': '銀角零卡-無卡分期',
    },
  };
};

const components = {
  continueBar,
  queryOrderWording,
};

const filters = {
  dollar,
  NumberWithPoint,
};

const props = {
  order: Object,
  paymentMethod: String,
  isMobile: Boolean,
};

export default {
  name: 'others',
  data,
  components,
  filters,
  props,
};
</script>
