<template>
  <div>
    <div class="text-left spacing top" v-if="isMobile">
      <b class="fn-lg blue">神腦生活提醒您</b>
      <p class="text-muted spacing top sm">
        詐騙手法日新月異層出不窮，訂單成立後，我們不會主動與您聯繫要求修改訂單付款方式或行銷推廣通知，如您接獲此類電話，請不要依照指示操作。
      </p>
      <h5 class="divider horizontal">牢記詐騙三「不」驟，遠離歹徒惡虎口。</h5>
      <ul class="has-type-decimal warning sticky">
        <li>「不」碰ATM</li>
        <li>「不」提供個人資料</li>
        <li>
          &nbsp;接到電話「不」要慌
          <br />
          &nbsp;建議您可與本公司客服中心聯繫查證。
        </li>
      </ul>
    </div>
    <!-- PC -->
    <div class="warn_box border top" v-else>
      <h3 class="blue bill-hami">神腦生活提醒您</h3>
      <p class="spacing bottom">
        詐騙手法日新月異層出不窮，訂單成立後，我們不會主動與您聯繫要求修改訂單付款方式或行銷推廣通知，如您接獲此類電話，請不要依照指示操作。
      </p>
      <h5 class="fn-root">牢記詐騙三「不」驟，遠離歹徒惡虎口。</h5>
      <ul class="has-type-decimal warning fn-root spacing bottom md">
        <li>「不」碰ATM</li>
        <li>「不」提供個人資料</li>
        <li>
          &nbsp;接到電話「不」要慌
          <br />
          &nbsp;建議您可與本公司客服中心聯繫查證。
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
const props = {
  isMobile: Boolean,
};

export default {
  name: 'avoid-fraud',
  props,
};
</script>
