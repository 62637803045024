<!-- TODO: 確認神腦生活 & much much繼續購物和訂單查詢href -->
<template>
  <div>
    <div class="btn-group">
      <a :href="goBackUrl" :class="continueButtonClass"> 繼續購物 </a>
      <a :href="orderListUrl" :class="orderCheckButtonClass"> 訂單查詢 </a>
    </div>
    <template v-if="isShowInsurance">
      <a
        class="btn btn-query btn-round"
        target="_blank"
        v-for="(item, index) of linkList"
        :key="index"
        :href="item.link">
        {{ item.text }}
      </a>
    </template>
  </div>
</template>

<script>
import { getState, renderState } from '@/helpers/vuex';
import { getGoBackWhere } from '@/helpers/is-from-app';
import { pcOrderListUri, mobileOrderListUri, online, cocoBack } from '@/components/common/cart-finished-constant';

// 是否顯示手機安心保按鈕？
const isShowInsurance = getState('OrderFinishInfo', 'isShowInsurance');

const props = {
  isMobile: Boolean,
};

const methods = {
  setUrl() {
    switch (getGoBackWhere()) {
      case 1:
        this.goBackUrl = `${online}/goBackApp`;
        this.orderListUrl = mobileOrderListUri;
        return;
      case 2:
        this.goBackUrl = `${cocoBack}/`;
        return;
      default:
        if (this.isMobile) {
          this.orderListUrl = mobileOrderListUri;
        }
    }
  },
};

const computed = {
  continueButtonClass() {
    return this.isMobile ? 'btn btn-primary btn-round' : 'btn btn-secondary';
  },
  orderCheckButtonClass() {
    return this.isMobile ? 'btn btn-secondary btn-round' : 'btn btn-primary';
  },
  isShowInsurance,
  linkList() {
    const Order = renderState('OrderFinishInfo', 'Order');
    return Order.links;
  },
};

const mounted = function () {
  this.setUrl();
};

export default {
  name: 'continue-buy-button',
  data() {
    return {
      goBackUrl: online,
      orderListUrl: pcOrderListUri,
    };
  },
  props,
  methods,
  computed,
  mounted,
};
</script>
